import { SurveyInterface, SurveyResponseInterface } from './SurveyInterface';

export const READY = 'ready';
export const IN_PROGRESS = 'in progress';
export const COMPLETED = 'completed';

interface SurveyState {
  responses: SurveyResponseInterface[];
  currentResponseIndex: number;
  currentResponse: SurveyResponseInterface;
}

interface SurveyAction {
  type: 'start' | 'answer' | 'skip' | 'back';
}

const SurveyReducer = (state: SurveyState, action: SurveyAction) => {
  const { responses } = state;
  const update: any = {};

  switch (action.type) {
    case 'start':
      break;
    case 'answer':
      update.currentResponseIndex = state.currentResponseIndex + 1;
      break;
    case 'skip':
      update.currentResponseIndex = state.currentResponseIndex + 1;
      break;
    case 'back':
      update.currentResponseIndex = state.currentResponseIndex > 0 ? state.currentResponseIndex - 1 : 0;
      break;
    default:
  }

  update.currentResponse = responses[update.currentResponseIndex];
  update.currentState = getCurrentState(responses.length, update.currentResponseIndex);

  return { ...state, ...update };
};
export default SurveyReducer;

const getCurrentState = (responseCount: number, currentResponseIndex: number) => {
  let currentState;
  if (currentResponseIndex < responseCount) {
    currentState = IN_PROGRESS;
  } else {
    currentState = COMPLETED;
  }
  return currentState;
};

export const initialState = (survey: SurveyInterface) => {
  const responses = survey.responses.edges.map(edge => edge.node);
  const reducer = (highestIndex: number, response: SurveyResponseInterface, currentIndex: number) =>
    response.answer !== null || response.skippedAt !== null ? currentIndex : highestIndex;
  const highestAnsweredOrSkippedResponseIndex = responses.reduce(reducer, -1);
  const initialResponseIndex = highestAnsweredOrSkippedResponseIndex !== -1 ? highestAnsweredOrSkippedResponseIndex + 1 : 0;

  return {
    responses: responses,
    currentResponseIndex: initialResponseIndex,
    currentResponse: responses[initialResponseIndex],
    currentState: getCurrentState(responses.length, initialResponseIndex),
  };
};
