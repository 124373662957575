import React, { useState, useEffect, useReducer } from 'react';
import { useMutation } from '@apollo/client';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import PageStyle from '../UI/PageStyle';
import Response from '../Survey/Response';
import SecondaryNavigation, { SecondaryNavigationHeading, SecondaryNavigationActions } from '../Navigation/SecondaryNavigation';
import SurveyReducer, { initialState, IN_PROGRESS, COMPLETED } from '../Survey/SurveyReducer';
import { ANSWER_RESPONSE_MUTATION, SKIP_RESPONSE_MUTATION } from './useSurvey';
import { SurveyAnswerInterface, SurveyInterface, SurveyResponseInterface } from './SurveyInterface';

const ERROR_MESSAGE = 'Oops! There was an error submitting your response. Please try again.';

interface SurveyProps {
  survey: SurveyInterface;
  onStop: () => void;
  onComplete: (survey: SurveyInterface) => void;
}

const Survey = ({ survey, onStop, onComplete }: SurveyProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const [state, dispatch] = useReducer(SurveyReducer, survey, initialState);
  const { currentState, currentResponseIndex, currentResponse } = state;

  const [answerResponseMutation] = useMutation(ANSWER_RESPONSE_MUTATION);
  const [skipResponseMutation] = useMutation(SKIP_RESPONSE_MUTATION);

  useEffect(() => {
    if (currentState === COMPLETED) {
      onComplete(survey);
    }
  }, [currentState, onComplete, survey]);

  const handleAnswer = async (response: SurveyResponseInterface, answer: SurveyAnswerInterface) => {

    setSubmitting(true);
    setErrorMessage(null);
    const {
      data: { answerResponse },
    } = await answerResponseMutation({
      variables: {
        responseId: response.id,
        answerId: answer.id,
      },
    });

    if (answerResponse.success) {
      dispatch({ type: 'answer' });
    } else {
      console.error(answerResponse);
      setErrorMessage(ERROR_MESSAGE);
    }
    setSubmitting(false);
  };

  const handleBack = () => {
    setErrorMessage(null);
    dispatch({ type: 'back' });
  };

  const handleSkip = async () => {
    setErrorMessage(null);
    setSubmitting(true);
    const {
      data: { skipResponse },
    } = await skipResponseMutation({ variables: { id: currentResponse.id } });

    if (skipResponse.success) {
      dispatch({ type: 'skip' });
    } else {
      console.error(skipResponse);
      setErrorMessage(ERROR_MESSAGE);
    }

    setSubmitting(false);
  };

  const surveyName = `${survey.scorable.name} - ${survey.module.name}`;

  if (currentState === IN_PROGRESS) {
    return (
      <React.Fragment>
        <SecondaryNavigation progressStepIndex={currentResponseIndex} progressStepCount={state.responses.length}>
          <SecondaryNavigationHeading>
            <Typography variant='h6' className='title'>{`${surveyName} - Question ${currentResponseIndex + 1} of ${state.responses.length}`}</Typography>
          </SecondaryNavigationHeading>
          <SecondaryNavigationActions>
            <Stack direction='row' spacing={1} alignItems='center' >
              <Button startIcon={<ArrowBackIcon />} disabled={currentResponseIndex === 0} onClick={handleBack}>
                Back
              </Button>
              <Button disabled={submitting} endIcon={<ArrowForwardIcon />} onClick={handleSkip}>
                Skip
              </Button>
              <Divider orientation='vertical' flexItem />
              <Stack direction='row' alignItems='stretch' >
                <IconButton disabled={submitting} onClick={onStop}>
                  <CloseRoundedIcon />
                </IconButton>
              </Stack>
            </Stack>
          </SecondaryNavigationActions>
        </SecondaryNavigation>

        <PageStyle design='floating'>
          {errorMessage && (
            <p>
              <Alert severity='error'>{ERROR_MESSAGE}</Alert>
            </p>
          )}
          <Response response={currentResponse} onAnswer={handleAnswer} submitting={submitting} />
        </PageStyle>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default Survey;
