import React, { useRef, useState } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import AppBackground from './AppBackground';
import AppSurface from './AppSurface';
import LoadingSection from '../UI/LoadingSection';
import { useLayout } from './LayoutContext';
import { DesignType, useDesignTheme } from './DesignThemes';
import { Box, ButtonBase, Divider, IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import HyveLogoCentered from './HyveLogoCentered';
import HeaderLogo from './HeaderLogo';
import { NAVIGATION_THEME } from './NavigationTheme';
import { AccountCircleRounded } from '@mui/icons-material';
import { useAuthentication } from '../User/AuthenticationContext';
import { useNavigate } from 'react-router-dom';

type PageStyleProps = {
  design?: DesignType;
  theme?: string;
  loading?: boolean;
  notice?: React.ReactNode;
  fullWidth?: boolean;
  children?: React.ReactNode;
  includeHeader?: boolean;
  includeBrandedLogo?: boolean; // only applies to the branded design
  backgroundProps?: Object;
  surfaceProps?: Object;
};

const PageStyle = ({
  design = 'standard',
  loading = false,
  notice = undefined,
  fullWidth = true,
  children,
  includeHeader = false,
  includeBrandedLogo = true,
  backgroundProps,
  surfaceProps,
}: PageStyleProps) => {
  const currentTheme = useTheme();
  const designThemeOptions = useDesignTheme(design);
  const layout = useLayout();

  let pageTheme = currentTheme;
  pageTheme = deepmerge(pageTheme, designThemeOptions);

  if (loading) {
    children = <LoadingSection />;
  }

  let content;
  if (design === 'standard') {
    content = children;
  } else if (design === 'custom') {
    content = children;
  } else if (design === 'branded') {
    fullWidth = true;
    content = (
      <AppSurface {...surfaceProps}>
        {includeBrandedLogo && <HyveLogoCentered marginTop='40px' marginBottom='16px' />}
        {children}
      </AppSurface>
    );
  } else {
    content = <AppSurface {...surfaceProps}>{children}</AppSurface>;
  }

  let maxWidth = 1080;
  if (layout === 'desktop') {
    maxWidth = 1600;
  }

  const sx = {
    ...(fullWidth && { width: '100%', alignItems: 'stretch' }),
    maxWidth: `${maxWidth}px`,
    ...(design === 'branded' && { alignItems: 'center', justifyContent: 'center' }),
  };

  return (
    <ThemeProvider theme={pageTheme}>
      {includeHeader && <Header />}
      <AppBackground {...backgroundProps}>
        {notice}
        <Stack flex={1} spacing={4} className='app-page' alignItems='center' sx={sx}>
          {content}
        </Stack>
      </AppBackground>
    </ThemeProvider>
  );
};

const Header = () => {
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const { authenticated, deauthenticate } = useAuthentication();
  const navigate = useNavigate();
  const accountButtonRef = useRef();

  const handleSignOut = () => {
    console.log('sign out');
    deauthenticate();
    navigate('/sign-in');
  };

  const handleSignIn = () => {
    navigate('/sign-in');
  };

  const handleAccountMenuClose = () => {
    setAccountMenuOpen(false);
  };

  return (
    <ThemeProvider theme={NAVIGATION_THEME}>
      <Stack data-component='Header' direction='row' height='64px' sx={{ backgroundColor: '#000' }} alignItems='center' justifyContent='space-between'>
        <ButtonBase href='/'>
          <HeaderLogo />
        </ButtonBase>
        <Divider orientation='vertical' />
        <Stack p={1} justifyContent='center'>
          <Box ref={accountButtonRef}>
            <Tooltip title='Account' disableInteractive>
              <IconButton data-cy='account-button' onClick={() => setAccountMenuOpen(true)}>
                <AccountCircleRounded />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
        <Menu open={accountMenuOpen} anchorEl={accountButtonRef.current} onClose={handleAccountMenuClose}>
          {authenticated ? <MenuItem onClick={handleSignOut}>Sign Out</MenuItem> : <MenuItem onClick={handleSignIn}>Sign In</MenuItem>}
        </Menu>
      </Stack>
    </ThemeProvider>
  );
};

export default PageStyle;
